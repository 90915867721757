.cntr {
  display: flex;
  flex-direction: column;
  gap: 0 var(--spacing-10);
  margin-bottom: var(--spacing-10);
  align-items: space-between;
  justify-content: space-between;
  width: 100%;

  @media (--desktop) {
    flex-direction: row;
    gap: 48px var(--spacing-10);
    padding-top: var(--spacing-8);
  }

  @media (--desktop-l) {
    padding-top: var(--spacing-16);
  }
}

.brdcrmb {
  margin: var(--spacing-10) 0;
  width: 100%;
}

.colL {
  display: contents;
  flex-direction: column;
  flex: 1 0 auto;

  @media (--desktop) {
    max-width: calc(100% - 348px);
    display: flex;
  }
}

.colR {
  min-width: 0;
  display: none;
  width: 300px;
  padding-top: 281px;

  @media (--desktop) {
    display: block;
  }
}

.moduleSpacing {
  & .brdcrmb {
    margin: var(--spacing-10) 0;
  }

  & :global(.blogCmpnt) {
    max-width: 1440px;
  }

  & > ul {
    margin: var(--spacing-10) 0;
  }

  & > :global(.blogCmpnt):first-child {
    margin-top: var(--spacing-10);
    margin-bottom: 0;
  }

  & > :global(section.guideSectionModule):first-child {
    margin-top: 0;
  }

  &
    > :global(section.rich-text-component)
    > :global(.grid-row)
    > :global(.grid-column)
    > :global(.rich-text-element)
    > *:last-child {
    margin-bottom: 0;
  }

  &
    > :global(section.rich-text-component)
    > :global(.grid-row)
    > :global(.grid-column)
    > :global(.rich-text-element)
    > *:first-child {
    margin-top: 0;
  }

  & > :global(.blogCmpnt) + :global(.blogCmpnt) {
    margin-top: var(--spacing-10);
  }

  & > :global(.coloredModule):last-child {
    margin-bottom: 0;
  }

  & > :global(.coloredModule) + :global(.coloredModule) {
    margin-top: 0;
  }

  & > :global(.coloredModule) + :global(.guideSectionModule) {
    margin-top: 0;
  }

  /*
   * 768
   */

  @media (min-width: 768px) {
    & > :global(.blogCmpnt):first-child {
      margin-top: var(--spacing-12);
    }

    & > :global(.blogCmpnt) + :global(.blogCmpnt) {
      margin-top: var(--spacing-12);
    }

    & > :global(.blogCmpnt):last-child {
      margin-bottom: var(--spacing-12);
    }

    & > :global(.coloredModule) + :global(section.guideSectionModule) {
      margin-top: var(--spacing-12);
    }

    & > :global(.guideSectionModule):first-child {
      margin-top: var(--spacing-12);
    }
  }

  /*
   * 1280
   */

  @media (min-width: 1280px) {
    & :global(.blogCmpnt):not(:global(.searchFormHero)) {
      padding-right: var(--spacing-16);
      padding-left: var(--spacing-16);
    }
    & :global(.blogCmpnt.searchFormHero) {
      padding-right: 0;
      padding-left: 0;
    }

    & > :global(.blogCmpnt):first-child {
      margin-top: var(--spacing-18);
    }

    & > :global(.blogCmpnt) + :global(.blogCmpnt) {
      margin-top: var(--spacing-18);
    }

    & > :global(.blogCmpnt):last-child {
      margin-bottom: var(--spacing-18);
    }

    & > :global(.coloredModule) + :global(section.guideSectionModule) {
      margin-top: var(--spacing-18);
    }

    & > :global(.guideSectionModule):first-child {
      margin-top: var(--spacing-18);
    }
  }
}

.adMob,
.adDsktp {
  &:empty {
    display: none;
  }
}

.adDsktp {
  margin: var(--spacing-5) 0;
  display: none;

  @media (--tablet-l) {
    display: block;
  }
}

.adMob {
  margin: var(--spacing-5) 0;
  display: block;

  @media (--tablet) {
    display: none;
  }
}

.mBAd {
  display: block;
  width: calc(100% + 40px);
  position: sticky;
  bottom: 0;
  z-index: 99;
  margin-left: -20px;

  [data-has-footer-sticky='true'] & {
    bottom: 52px;
    @media (--tablet-l) {
      bottom: 0;
    }
  }

  @media (--tablet) {
    display: none;
  }

  > div {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 50;
    padding: (--spacing-2) 0;

    [data-has-footer-sticky='true'] & {
      bottom: 52px;
      @media (--tablet-l) {
        bottom: 0;
      }
    }
  }

  &&& {
    margin-bottom: var(--spacing-2);
  }
}
