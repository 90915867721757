.controls {
  position: absolute;
  bottom: var(--spacing-5);
  width: 100%;
  display: flex;
  padding: 0;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  z-index: 2;

  @media (--tablet-l) {
    bottom: var(--spacing-2);
    align-items: center;
    justify-content: space-between;

    &[data-variant='large'],
    &[data-variant='review'] {
      width: 242px;
    }

    &[data-variant='largest'] {
      bottom: 50%;
      width: 100vw;

      .caroBtn:first-child {
        left: 32px;
      }
      .caroBtn:last-child {
        right: 32px;
      }
    }
  }

  @media (--desktop-l) {
    &[data-variant='largest'] {
      max-width: 1440px;
      .caroBtn:first-child {
        left: 64px;
      }
      .caroBtn:last-child {
        right: 64px;
      }
    }
  }

  &[data-variant='small'] {
    padding: 0 var(--spacing-2);
  }
}

.caroBtn {
  && {
    display: none;
    background: var(--color-overlay);
    color: var(--color-championship-white);
    border-radius: 4px;
    cursor: pointer;
    pointer-events: all;
    transition: 0.1s;

    &:disabled {
      color: var(--color-tarmac-grey-600);
      background-color: var(--color-tarmac-grey-700);
    }

    &:hover {
      background-color: var(--color-tarmac-grey-700);
    }

    &:active {
      background-color: var(--color-tarmac-grey-800);
    }

    @media (--tablet-l) {
      display: flex;
      width: 32px;
    }
  }
}

.dots {
  position: relative;
  display: flex;
  justify-content: center;
  height: 8px;
  cursor: default;
}

.dot {
  background-color: var(--color-championship-white);
  border-radius: 50%;
  border: 1px solid var(--color-neutral-700);

  opacity: 0.5;
  position: absolute;
  top: 0;
  transform-origin: left center;
  transition: all 400ms ease-out;
  transform: var(--transform);
  cursor: default;
  &.dotActive {
    opacity: 1;
  }
  &.smallDots {
    width: 8px;
    height: 8px;
  }

  &.largerDots {
    width: 10px;
    height: 10px;
  }
}
