.notif {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-3);
  width: 100%;
  color: var(--color-tarmac-grey-700);
  background-color: var(--color-tarmac-grey-100);
  line-height: 18px;
  position: fixed;
  left: 0;
  z-index: 52;
  transform: translateY(41px);
  opacity: 0;
  visibility: hidden;
  transition: transform 0.3s, opacity 0.3s;

  &.show {
    transform: translateY(45px);
    opacity: 1;
    visibility: visible;
  }
}
